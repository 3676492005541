import React, {useState} from "react";
import styled from "styled-components";
import {SearchFilter} from './SearchFilter';
import {IconCheck, IconPlay, IconPlus, SortTable} from "./BlitzTable";
import {Col, Container, Row} from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { playerActions, playlistActions, songsActions } from "../../_actions";
import {getActivePlaylistId, getPlaylistLookupView,} from "../../_reducers/playlist.reducer";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

const ContainerStyled = styled(Container)`
    padding: 10px;
    color: #BBB;
    user-select: none;
    min-width: 500px;
    background-color: #222128;
    border-radius: 5px;
    border: 1px solid #525252;
    margin-top: 10px;
`;

export const ReactTooltipStyled = styled(ReactTooltip)`
    background: white !important;
    color: black !important;
    border: 1px solid grey !important;

    .place-left::after {
        border-left: 8px solid grey !important;
    }

    .place-right::after {
        border-right: 8px solid grey !important;
    }

    .place-top::after {
        border-top: 8px solid grey !important;
    }

    .place-bottom::after {
        border-bottom: 8px solid grey !important;
    }


`;

const SelectionContextMenuSongs = ({activePlaylistId, activeSongs, songsData, dispatch, songStatusChanged}) => {
    const [filter, setFilter] = useState('');
    const handleFilter = text => {
        setFilter(text);
    }

    const handleAddSong = song => {
        playlistActions.addSongs(dispatch, activePlaylistId, [song.id])
        if (songStatusChanged) {
            songStatusChanged(song);
        }

    };

    const handleDeleteSong = song => {
        playlistActions.deleteSongs(dispatch, activePlaylistId, [song.id])
        if (songStatusChanged) {
            songStatusChanged(song);
        }
    };

    let columns = [
        {
            fixed: 30, rowRenderer: row => {
                return activeSongs[row.id] ? (
                    <IconCheck color='orange' onClick={e => {
                        e.stopPropagation();
                        handleDeleteSong(row)
                    }}/>
                ) : (
                    <IconPlus onClick={e => {
                        e.stopPropagation();
                        handleAddSong(row)
                    }}/>
                )
            }
        },
        {
            fixed: 30, rowRenderer: row => (
                <div className="onRowHover">
                    <IconPlay onClick={e => {
                        e.stopPropagation();
                        playSong(row);
                    }}/>
                </div>
            )
        },
        {percent: 0.4, field: 'title', title: 'Title', isNumber: true},
        {percent: 0.4, field: 'artist.name', title: 'Artist', isNumber: true}
    ]

    const playSong = (song) => {
        playerActions.play(dispatch, song.id, song.title, song.artist.name, song.artworkUrl);
    }

    const filteredSongs = songsData.filter(s => s.title.toLowerCase().includes(filter.toLowerCase()) || s.artist.name.toLowerCase().includes(filter.toLowerCase()));

    return (
        <ContainerStyled>
            <Row>
                <Col style={{maxHeight: 600, overflowY: "scroll"}}>
                    <Row>
                        <Col xs={12}>
                            <SearchFilter onChange={handleFilter} value={filter}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SortTable
                                tableName={'songs_context'}
                                defaultSortField='title'
                                defaultSortOrder='desc'
                                loading={false}
                                rows={filteredSongs}
                                columns={columns}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </ContainerStyled>
    )
}

const mapStateToProps = (state, ownProps) => {
    const activePlaylistId = getActivePlaylistId(state);
    return {
        activePlaylistId,
        activeSongs: getPlaylistLookupView(state, activePlaylistId),
    };
};

const connectedSelectionContextMenuSongs = withRouter(connect(mapStateToProps)(SelectionContextMenuSongs));
export {connectedSelectionContextMenuSongs as SelectionContextMenuSongs};